import { ArrowRightOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, Row, Select, Space, Table, Tag, Tooltip } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Client } from "../../../core/api-client";
import { getEnquiryDashboardData } from "../../../core/store/reducers/lead-management.slice";
import { getCenterList, getMasterDropdownData } from "../../../core/store/reducers/masters.slice";
import TabGroupComponent from "../../../shared/components/tab-group/tab-group.component";
import { API_BASE_URL } from "../../../shared/constants";
import { API_DOWNLOAD_DAILY_REPORT, API_DOWNLOAD_MONTHLY_REPORT } from "../../../shared/routes/lms.routes";
import { RootState } from "../../../shared/types/common";
import { getTagColor } from "../../../shared/utils/utils";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, ChartDataLabels);

const CounsellorDashboardPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const [dashboardData, setDashboardData] = useState({} as any);
  const [sessions, setSessions] = useState([] as any);
  const [sessionId, setSessionId] = useState(null as any);
  const [showDDR, setShowDDR] = useState(false as any);
  const [showDMR, setShowDMR] = useState(false as any);
  const [centers, setCenters] = useState([] as any);
  const [centerId, setCenterId] = useState(null as any);
  useEffect(() => {
    dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
      if (val?.payload?.data?.success) {
        setSessions(val?.payload?.data?.data?.map((val: any) => {
          return { label: val.session, value: val._id }
        }))
        if (val?.payload?.data?.data?.length > 0)
          setSessionId(val?.payload?.data?.data?.[0]?._id)
      }
    })
    dispatch(getCenterList()).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        setCenters(
          val.payload.data.data.map((val: any) => {
            return { label: val.center_name, value: val._id };
          })
        );
      }
    });
  }, []);
  function getDashboardData() {
    dispatch(getEnquiryDashboardData({ sessionId, ...(centerId ? { centerId: centerId } : {}) })).then((val: any) => {
      if (val?.payload?.data?.success && val?.payload?.data?.data) {
        let hotCount = getCountBy_id(
          val?.payload?.data?.data?.rating_counts,
          "Hot"
        );
        let warmCount = getCountBy_id(
          val?.payload?.data?.data?.rating_counts,
          "Warm"
        );
        let coldCount = getCountBy_id(
          val?.payload?.data?.data?.rating_counts,
          "Cold"
        );
        let statusNames: any = [];
        let statusCounts: any = [];
        val?.payload?.data?.data?.status_counts.forEach((statusData: any) => {
          statusNames.push(statusData._id);
          statusCounts.push(statusData.count);
        });
        let counsellorNames: any = [];
        let counsellorCounts: any = [];
        val?.payload?.data?.data?.counsellor_counts?.forEach((cData: any) => {
          counsellorNames.push(cData.counsellor_name);
          counsellorCounts.push(cData.count);
        });

        setDashboardData({
          ...val.payload.data.data,
          status_names: statusNames,
          status_counts: statusCounts,
          counsellor_names: counsellorNames,
          counsellor_counts: counsellorCounts,
          hot_count: hotCount,
          rating_counts: [hotCount, warmCount, coldCount],
        });
      }
    });
  }
  const getCountBy_id = (obj: any, key: string) => {
    let filteredData = obj?.filter((val: any) => {
      return val._id === key;
    });
    return filteredData?.length > 0 ? filteredData[0]["count"] : 0;
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Comparison",
      },
      datalabels: {
        display: true,
        color: 'black',
        font: {
          size: 14
        }
      }
    },

  };
  const labelsCoun = dashboardData.counsellor_names;
  const dataCoun = {
    labels: labelsCoun,
    datasets: [
      {
        label: "Counts",
        data: dashboardData?.counsellor_counts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(30, 150, 200, 0.5)",
        ],
      },
    ],
  };
  const labelsRating = ["Hot", "Warm", "Cold"];
  const dataRating = {
    labels: labelsRating,
    datasets: [
      {
        label: "Counts",
        data: dashboardData?.rating_counts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(30, 150, 200, 0.5)",
        ],
      },
    ],
  };
  const labelsStatus = dashboardData.status_names;
  const dataStatus = {
    labels: labelsStatus,
    datasets: [
      {
        label: "Counts",
        data: dashboardData?.status_counts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(30, 150, 200, 0.5)",
        ],
      },
    ],
  };
  const followUpColumns: any = [
    {
      title: "Name",
      dataIndex: "user_name",
      sorter: true,
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "user_email",
      sorter: true,
      key: "user_email",
    },
    {
      title: "Mobile",
      dataIndex: "user_number",
      sorter: true,
      key: "user_number",
    },
    {
      title: "COURSE",
      dataIndex: ["course", "course_name"],
      sorter: true,
      key: "course",
      className: "capitalize",
    },
    {
      title: "CENTRE",
      dataIndex: ["centre", "center_name"],
      sorter: true,
      key: "centre",
      className: "capitalize",
    },
    {
      title: "Counsellor",
      sorter: true,
      key: "current_counsellor_name",
      render: (a: any) => {
        return a.current_counsellor?.name;
      },
    },
    {
      title: "Rating",
      dataIndex: "rating",
      sorter: true,
      key: "rating",
      render: (text: any) => (
        <>
          {text ? <Tag color={getTagColor(text, "rating")}>{text}</Tag> : <></>}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "lead_status",
      sorter: true,
      key: "lead_status",
    },
    {
      title: "Actions",
      width: "50px",
      fixed: "right",
      render: (a: any) => {
        return (
          <>
            <Tooltip title={"Details"}>
              <Button
                onClick={() => navigate("/enquiry-details/" + a._id)}
                type="link"
                className="table-button"
                icon={<ArrowRightOutlined />}
              ></Button>
            </Tooltip>
          </>
        );
      },
      key: "actions",
    },
  ];
  const [startdate, setStartdate] = useState(moment().subtract(30, 'd') as any);
  const [enddate, setEnddate] = useState(moment() as any);
  useEffect(() => {
    if (startdate && enddate && sessionId) {
      let start = startdate.format('YYYY-MM-DD')
      let end = enddate.format('YYYY-MM-DD')
      let str = start + "-to-" + end;
      getDashboardData()
      getTableData(str)
    }
  }, [startdate, enddate, sessionId])
  useEffect(() => {
    if (centerId) {
      let start = startdate.format('YYYY-MM-DD')
      let end = enddate.format('YYYY-MM-DD')
      let str = start + "-to-" + end;
      getDashboardData()
      getTableData(str)
    }
  }, [centerId])
  const [tableData, setTableData] = useState({ columns: [], data: [] } as any)
  async function getTableData(str: string) {
    const ret = await Client.get(API_DOWNLOAD_MONTHLY_REPORT(str), { params: { type: 'json', sessionId: sessionId, ...(centerId ? { centerId: centerId } : {}) } });
    if (ret?.data?.data?.length > 0) {
      let columns = ret?.data?.data[0];
      let columnsFinal = columns?.map((val: any) => {
        return {
          title: val,
          dataIndex: val,
          key: val,
          // render: (key:any) => {
          //   if(val == 'TOTAL ENQ.'){
          //     return <a href={`/enquiries?current_counsellor`} target="_blank"><strong>{val}</strong></a>
          //   }
          //   return val;
          // }
          // sorter: (a: any, b: any) => a - b,
        }
      })
      const lines = ret?.data?.data;
      let dataSource = [];
      for (let i = 1; i < lines.length; i++) {
        const obj = {} as any;
        const currentLine = lines[i];
        for (let j = 0; j < columns.length; j++) {
          obj[columns[j].trim()] = currentLine[j];
        }
        dataSource.push(obj);
      }
      dataSource = dataSource?.sort((a: any, b: any) => b['TOTAL ADM.'] - a['TOTAL ADM.'])
      setTableData({ columns: columnsFinal, data: dataSource })
    }

  }
  return (
    <>
      <Row justify="end" className="mt-4 mx-4">
        <Col flex="none">
          <Space>
            {user?.user_role == "admin" && <Select onChange={(e) => setCenterId(e)} value={centerId} style={{ width: '200px' }} placeholder="Select Center">
              {centers?.map((val: any) => <Select.Option key={val.value} value={val.value}>{val.label}</Select.Option>)}
            </Select>}
            {sessionId && <Select onChange={(e) => setSessionId(e)} value={sessionId} style={{ width: '200px' }}>
              {sessions?.map((val: any) => <Select.Option key={val.value} value={val.value}>{val.label}</Select.Option>)}
            </Select>}
            {user?.user_role == "admin" && <><Button type="primary" icon={<DownloadOutlined />} onClick={() => setShowDDR(true)}>Daily Report</Button>
              <Button type="primary" icon={<DownloadOutlined />} onClick={() => setShowDMR(true)}>Monthly Report</Button></>}
          </Space>

        </Col>
      </Row>
      <Row className="mt-4 mx-4">
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3901/3901662.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.lead_count}</h1>
                <p className="mb-0 weight-700">LEADS</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2621/2621098.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.conversions}</h1>
                <p className="mb-0 weight-700">CONVERSIONS</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3275/3275168.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.hot_count}</h1>
                <p className="mb-0 weight-700">HOT LEADS</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3893/3893198.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.follow_up_count}</h1>
                <p className="mb-0 weight-700">FOLLOW-UP DUE</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mx-4 mt-2">
        {/* <Col span={12} className="p-2">
          <div
            className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4"
            style={{ height: "400px", paddingBottom: "70px" }}
          >
            <h3>Follow-Ups (Due and Upcoming)</h3>
          </div>
        </Col> */}
        {dashboardData && user?.user_role !== "admin" && user?.user_role !== "lms-admin" && user?.user_role !== "center-head" && (
          <Col span={18}>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 px-4 pb-4 mb-2"
              style={{ height: "400px", paddingBottom: "70px" }}
            >
              <TabGroupComponent
                defaultActiveKey="1"
                allTabs={[
                  {
                    title: "Today's Follow-Ups",
                    component: (
                      <Table
                        size="small"
                        loading={false}
                        dataSource={dashboardData.follow_up_list_today}
                        columns={followUpColumns}
                        scroll={{ x: "1000px" }}
                        tableLayout="auto"
                      />
                    ),
                  },
                  {
                    title: "Overdue Follow-Ups",
                    component: (
                      <Table
                        size="small"
                        loading={false}
                        dataSource={dashboardData.follow_up_list_follow_up_due}
                        columns={followUpColumns}
                        scroll={{ x: "1000px" }}
                        tableLayout="auto"
                      />
                    ),
                  },
                ]}
              ></TabGroupComponent>
            </div>
          </Col>
        )}
        {dashboardData && (user?.user_role === "admin" || user?.user_role === "lms-admin" || user?.user_role === "center-head") && (
          <Col span={18}>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4"
              style={{ paddingBottom: "70px" }}
            >
              {/* <h3>Enquiry Counts (by Counsellors)</h3>
              <Bar options={options} data={dataCoun} /> */}
              <Row align="middle">
                <Col sm={24} md={14}><h3>Enquiry Counts (by Counsellors)</h3></Col>
                <Col sm={24} md={10}><DatePicker.RangePicker value={[startdate, enddate]} onChange={(evt: any) => {
                  if (evt.length == 2) {
                    setStartdate(evt[0])
                    setEnddate(evt[1])
                  }

                }} /></Col>
              </Row>
              <Table
                size="small"
                loading={false}
                dataSource={tableData.data}
                columns={tableData.columns}
                scroll={{ x: "1000px", y: '800px' }}
              />
            </div>
          </Col>
        )}
        {dashboardData && (
          <Col span={6}>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4"
              style={{ height: "400px", paddingBottom: "70px" }}
            >
              <h3>Enquiry (by Rating)</h3>
              <Bar options={options} data={dataRating} />
            </div>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4 mt-3"
              style={{ height: "400px", paddingBottom: "70px" }}
            >
              <h3>Enquiry (by Status)</h3>
              <Bar options={options} data={dataStatus} />
            </div>
          </Col>
        )}

        {showDDR && <Modal title="Download Daily Report"
          open={showDDR}
          onCancel={() => setShowDDR(false)}
          // onOk={
          //   ()=>{
          //     showDDR(false)

          //   }
          // } 
          footer={false}
          centered={true}>
          <Form onFinish={
            (values: any) => {
              const { date } = values;
              let datestr = moment(date).format("YYYY-MM-DD");
              window.open(API_BASE_URL + API_DOWNLOAD_DAILY_REPORT(datestr))
            }
          }>
            <Form.Item name="date" rules={[{ required: true, message: "Please select date." }]}>
              <DatePicker />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" icon={<DownloadOutlined />}>Download Report</Button>
            </Form.Item>
          </Form>
        </Modal>}
        {showDMR && <Modal title="Download Monthy Report"
          open={showDMR}
          onCancel={() => setShowDMR(false)}
          // onOk={
          //   ()=>{
          //     showDDR(false)

          //   }
          // } 
          footer={false}
          centered={true}>
          <Form onFinish={
            (values: any) => {
              const { date } = values;
              let datestr = moment(date[0]).format("YYYY-MM-DD");
              let datestr2 = moment(date[1]).format("YYYY-MM-DD");
              window.open(API_BASE_URL + API_DOWNLOAD_MONTHLY_REPORT(datestr + '-to-' + datestr2))
            }
          }>
            <Form.Item name="date" rules={[{ required: true, message: "Please select date." }]}>
              <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" icon={<DownloadOutlined />}>Download CSV</Button>
            </Form.Item>
          </Form>
        </Modal>}
      </Row>
    </>
  );
};
export default CounsellorDashboardPage;
