import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Drawer,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Timeline,
  Tooltip
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { RiUserAddLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getCounsellorList,
  getEnquiryById,
  updateEnquiryById
} from "../../../core/store/reducers/lead-management.slice";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { RootState } from "../../../shared/types/common";
import {
  capitalizeWords,
  getTagColor,
  showNotification
} from "../../../shared/utils/utils";
import AdmissionForm from "../../admission/new-admission";
import CreateEditEnquiry from "./components/create-edit-enquiry.component";

const EnquiryDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedEntity, setSelectedEntity] = useState(null as any);
  const [createEditOpen, setCreateEditOpen] = useState(false);
  const [enquiryDetails, setEnquiryDetails] = useState(null as any);
  const { id } = useParams();
  const [counsellorList, setCounsellorList] = useState([] as any);
  const [admissionForm, setAdmissionForm] = useState(false as any);
  useEffect(() => {
    dispatch(getCounsellorList()).then((val: any) => {
      if (val?.payload?.data?.success && val?.payload?.data?.data?.length > 0) {
        setCounsellorList(
          val.payload.data.data.map((val: any) => {
            return { label: `${val.name}`, value: val._id };
          })
        );
      }
    });
  }, []);
  useEffect(() => {
    getEnquiryData();
  }, []);
  const getEnquiryData = () => {
    dispatch(getEnquiryById(id)).then((val: any) => {
      if (val?.payload?.data?.success && val?.payload?.data?.data) {
        setEnquiryDetails(val.payload.data.data);
      }
    });
  };
  const openAddEditPanel = (entity: any) => {
    setSelectedEntity(entity);
    setCreateEditOpen(true);
  };
  const createEditSubmit = () => {
    setCreateEditOpen(false);
    getEnquiryData();
  };
  const dataKeys = [
    "relation",
    "session",
    "centre",
    "course",
    "exam",
    "course_type",
    "college",
    "qualification",
    "address",
    "batch_timing",
    "rating",
    "lead_status",
    "lead_source",
    "lead_type",
    "action_taken",
    "remarks",
  ];
  const onFinish = (values: any) => {
    const payload = {
      ...values,
      enquiry_history: [
        ...enquiryDetails.enquiry_history,
        { ...values, created_by: user._id, created_at: moment().format() },
      ],
      _id: enquiryDetails._id,
      updated_by: user._id,
    };
    dispatch(updateEnquiryById(payload)).then((val: any) => {
      if (val?.payload?.data) {
        showNotification("Enquiry Updated Successfully", "success");
        if (
          user.user_role !== "admin" &&
          user.user_role !== "lms-admin" &&
          user.user_role !== "center-head" &&
          user._id !== enquiryDetails.current_counsellor._id
        ) {
          const payload2 = {
            current_counsellor: user._id,
            _id: enquiryDetails._id,
            updated_by: user._id,
            enquiry_history: [
              ...payload.enquiry_history,
              {
                current_counsellor: user._id,
                old_counsellor: enquiryDetails.current_counsellor._id,
                created_by: user._id,
                created_at: moment().format(),
              },
            ],
          };
          dispatch(updateEnquiryById(payload2)).then((val: any) => {
            if (val?.payload?.data) {
              getEnquiryData();
            }
          });
        } else {
          getEnquiryData();
        }
      }
    });
  };
  const onFinishFailed = () => { };
  const formProps = {
    name: "creat-edit-enquiry",
    initialValues: {
      rating: enquiryDetails?.rating,
      lead_status: enquiryDetails?.lead_status,
      next_follow_up_date: enquiryDetails?.next_follow_up_date
        ? moment(enquiryDetails?.next_follow_up_date)
        : null,
      remarks: enquiryDetails?.remarks,
    },
    formElements: [
      {
        name: "rating",
        label: "Rating",
        placeholder: "Rating",
        type: "Select",
        colSpan: 8,
        showSearch: true,
        allowClear: true,
        options: [
          { value: "Hot", label: "Hot" },
          { value: "Warm", label: "Warm" },
          { value: "Cold", label: "Cold" },
        ],
      },
      {
        name: "lead_status",
        label: "Lead Status",
        placeholder: "Lead Status",
        type: "Select",
        colSpan: 8,
        showSearch: true,
        allowClear: true,
        options: [
          { value: "New", label: "New" },
          { value: "Contacted", label: "Contacted" },
          { value: "Converted", label: "Converted" },
          { value: "Not Contactable", label: "Not Contactable" },
          { value: "Not Interested", label: "Not Interested" },
        ],
      },
      {
        name: "next_follow_up_date",
        label: "Next Follow-Up Date",
        placeholder: "Select Date Time",
        type: "DateTimePicker",
        allowClear: true,
        colSpan: 8,
        disabledDate: (d: any) => {
          return d <= moment();
        },
      },
      {
        name: "remarks",
        label: "Remarks",
        placeholder: "Remarks",
        type: "TextArea",
        colSpan: 24,
        rows: 4,
      },
    ],
    extraButtons: [
      {
        htmlType: "submit",
        colSpan: 5,
        loading: false,
        submitButtonText: "Save",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFinish,
    onFinishFailed: onFinishFailed,
  };
  return (
    <>
      <Row className="mx-6 mt-2">
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item key="list">
              <Link to="/enquiries">All Enquiries</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="details">Enquiry Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row
        className="mt-4 mx-4"
        style={{ maxHeight: "calc( 100vh - 140px )", overflow: "auto" }}
      >
        <Col span={8}>
          <div
            className=" mx-1 bg-primary-white-blueish border-primary-gray-02 p-3"
            style={{ borderBottom: "none!important" }}
          >
            {!enquiryDetails && (
              <Row align="middle" className="mb-2">
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Row>
            )}
            {enquiryDetails && (
              <Row align="middle" className="mb-2">
                <Col span={18}>
                  <h3 className="m-0">
                    {enquiryDetails?.user_name
                      ? enquiryDetails.user_name
                      : "Name not given"}
                  </h3>
                  <p>
                    {enquiryDetails?.user_email
                      ? enquiryDetails?.user_email
                      : "Email not given"}
                    <Divider type="vertical"></Divider>
                    {enquiryDetails?.user_number
                      ? enquiryDetails?.user_number
                      : "Mobile Number not given"}
                  </p>
                </Col>
                <Col span={6} className="text-right">
                  <Space>
                    <Tooltip title="Edit Enquiry">
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() => openAddEditPanel(enquiryDetails)}
                        icon={<EditOutlined />}
                      />
                    </Tooltip>
                    <Tooltip title="Start Admission">
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() => setAdmissionForm(true)}
                        icon={
                          <span role="img" className="anticon">
                            <RiUserAddLine />
                          </span>
                        }
                      />
                    </Tooltip>
                  </Space>

                  <Drawer title="Start Admission" width={"80%"} placement="right" onClose={() => setAdmissionForm(false)} open={admissionForm}>
                    <AdmissionForm onClose={() => setAdmissionForm(false)} enquiryDetails={enquiryDetails} id={enquiryDetails?._id}></AdmissionForm>
                  </Drawer>
                </Col>

                <Col span={24} className="mt-2">
                  {enquiryDetails.rating && (
                    <Tag color={getTagColor(enquiryDetails.rating, "rating")}>
                      {enquiryDetails.rating}
                    </Tag>
                  )}
                  {/* <Tag color="orange">Warm</Tag> */}
                  {/* <Tag color="blue">Cold</Tag> */}
                  <Tag color="purple">{enquiryDetails.lead_status}</Tag>
                  {/* <Tag color="green">Converted</Tag> */}
                  {/* <Tag>Not Interested</Tag> */}
                  {/* <Tag>Not Reachable</Tag> */}
                  {enquiryDetails.next_follow_up_date && (
                    <Tag color="#FF0000">
                      Follow Up Due:{" "}
                      {moment(enquiryDetails.next_follow_up_date).format(
                        "MMM DD, Y LT"
                      )}
                    </Tag>
                  )}
                </Col>
              </Row>
            )}
            <Divider />
            {enquiryDetails && (
              <>
                {dataKeys?.map((key: string) => {
                  return (
                    <Row className="mt-2">
                      <Col span={8} className="text-gray">
                        {capitalizeWords(key.split("_").join(" "))}
                      </Col>
                      <Col span={16}>
                        {enquiryDetails[key]
                          ? key === "exam"
                            ? (enquiryDetails?.exam?.exam_name || enquiryDetails.exam)
                            : key === "session"
                              ? enquiryDetails.session.session
                              : key === "centre"
                                ? enquiryDetails.centre.center_name
                                : key === "course"
                                  ? enquiryDetails.course.course_name
                                  : enquiryDetails[key]
                          : "--"}
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </div>
        </Col>
        <Col span={16}>
          {(user?._id === enquiryDetails?.current_counsellor._id || user?.user_role != "counsellor" || moment(enquiryDetails?.next_follow_up_date).isBefore(moment())) && <Row className="mx-1 bg-primary-white-blueish border-primary-gray-02">
            <Col span={24}>
              <Tabs className="table-status-tabs mx-3">
                <Tabs.TabPane
                  tab="Add Notes/Schedule Follow-up"
                  key="notes"
                  className="mt-4"
                >
                  {enquiryDetails && (
                    <GenericFormComponent {...formProps}></GenericFormComponent>
                  )}
                  {/* <Form >
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item label="Next Follow Up Date">
                          <DatePicker showTime={true}></DatePicker>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Enquiry Status">
                          <Select></Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Enter Notes">
                          <TextArea
                            rows={6}
                            placeholder="Enter Notes"
                          ></TextArea>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item>
                          <Button type="primary">Submit</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form> */}
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Add Follow-Up" key="follow-up">
                  <Form className="mt-4">
                    <Form.Item>
                      <DatePicker showTime={true}></DatePicker>
                    </Form.Item>
                    <Form.Item>
                      <TextArea rows={6}></TextArea>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary">Submit</Button>
                    </Form.Item>
                  </Form>
                </Tabs.TabPane> */}
              </Tabs>
            </Col>
          </Row>}
          <Row className="mx-1 bg-primary-white-blueish border-primary-gray-02 mt-4">
            <Col span={24} className="px-5 pt-2">
              <h3>Enquiry History</h3>
              {!enquiryDetails && (
                <Row align="middle" className="mb-2">
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </Row>
              )}
              {enquiryDetails && counsellorList && (
                <Timeline mode={"left"}>
                  {enquiryDetails?.enquiry_history?.length > 0 &&
                    enquiryDetails.enquiry_history
                      .reverse()
                      .map((action: any) => {
                        return (
                          <Timeline.Item>
                            <Row align="middle">
                              <Col span={3}>
                                {moment(action.created_at).format("MMM DD, Y")}
                                <br />
                                {moment(action.created_at).format("LT")}
                              </Col>
                              {!action?.current_counsellor && (
                                <Col
                                  span={21}
                                  className="p-3 border-primary-gray-02 bg-body"
                                >
                                  Enquiry marked as{" "}
                                  <strong>{action.lead_status}</strong> and{" "}
                                  <strong>{action.rating}</strong>
                                  {action.next_follow_up_date
                                    ? ` with follow-up scheduled for ${moment(
                                      action.next_follow_up_date
                                    ).format("MMM DD, Y LT")}`
                                    : ""}
                                  .
                                  <br />
                                  <em>Notes: {action.remarks}</em>
                                </Col>
                              )}
                              {action?.current_counsellor && (
                                <Col
                                  span={21}
                                  className="p-3 border-primary-gray-02 bg-body"
                                >
                                  Transfered to another counsellor
                                  <br />
                                  {action.old_counsellor &&
                                    counsellorList.filter((val: any) => {
                                      return (
                                        val.value === action.old_counsellor
                                      );
                                    })[0]?.label}{" "}
                                  <ArrowRightOutlined />{" "}
                                  {action.current_counsellor &&
                                    counsellorList.filter((val: any) => {
                                      return (
                                        val.value === action.current_counsellor
                                      );
                                    })[0]?.label}
                                </Col>
                              )}
                            </Row>
                          </Timeline.Item>
                        );
                      })}
                  <Timeline.Item>
                    <Row align="middle">
                      <Col span={3}>
                        {moment(enquiryDetails.created_at).format("MMM DD, Y")}
                        <br />
                        {moment(enquiryDetails.created_at).format("LT")}
                      </Col>
                      <Col
                        span={21}
                        className="p-3 border-primary-gray-02 bg-body"
                      >
                        Enquiry created
                      </Col>
                    </Row>
                  </Timeline.Item>
                </Timeline>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {createEditOpen && (
        <CreateEditEnquiry
          onClose={() => setCreateEditOpen(false)}
          visible={createEditOpen}
          selectedEnquiry={selectedEntity}
          onSubmit={createEditSubmit}
        />
      )}
    </>
  );
};
export default EnquiryDetailsPage;
