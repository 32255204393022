import { Drawer } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postBulkEnquiry,
  updateEnquiryById
} from "../../../../core/store/reducers/lead-management.slice";
import {
  getCenterList,
  getCourseListByCenter,
  getMasterDropdownData
} from "../../../../core/store/reducers/masters.slice";
import GenericFormComponent, {
  GenericFormProps
} from "../../../../shared/components/generic-form/generic-form.component";
import { LEAD_TYPES, RELATIONS, leadSources } from "../../../../shared/constants";
import { RootState } from "../../../../shared/types/common";
import {
  alphabetPattern,
  showNotification
} from "../../../../shared/utils/utils";

interface Props {
  onClose: () => void;
  visible: boolean;
  selectedEnquiry?: any;
  onSubmit?: () => void;
}
const CreateEditEnquiry = ({
  onClose,
  visible,
  selectedEnquiry = null,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { screenType } = useSelector((state: RootState) => state.settings);
  const [courses, setCourses] = useState([] as any);
  const [centers, setCenters] = useState([] as any);
  const [sessionList, setSessionList] = useState([] as any);
  const [exams, setExams] = useState([] as any);

  useEffect(() => {
    dispatch(getCenterList()).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        setCenters(
          val.payload.data.data.map((val: any) => {
            return { label: val.center_name, value: val._id };
          })
        );
      }
    });
    dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
      if (val?.payload?.data?.success) {
        setSessionList(val?.payload?.data?.data?.map((val: any) => {
          return { label: val.session, value: val._id }
        }))
      }
    })
    dispatch(getMasterDropdownData({ schemaKey: "exam", labelKey: "exam_name" })).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        setExams(
          val.payload.data.data.map((val: any) => {
            return { label: val.exam_name, value: val._id };
          })
        );
      }
    });
    if (selectedEnquiry?.centre?._id) {
      onValuesChange({ centre: selectedEnquiry?.centre?._id }, {});
    }
  }, []);
  const onFinish = (values: any) => {
    if (selectedEnquiry) {
      const payload = {
        ...values,
        _id: selectedEnquiry._id,
        updated_by: user._id,
      };
      dispatch(updateEnquiryById(payload)).then((val: any) => {
        if (val?.payload?.data) {
          showNotification("Enquiry Updated Successfully", "success");
          onSubmit();
        }
      });
    } else {
      const payload = {
        ...values,
        current_counsellor: user._id,
        created_by: user._id,
        updated_by: user._id,
      };
      dispatch(postBulkEnquiry({ enquiry_data: [payload] })).then(
        (val: any) => {
          if (val?.payload?.data) {
            showNotification("New Enquiry Created", "success");
            onSubmit();
          }
        }
      );
    }
  };

  const onFinishFailed = (_errorInfo: any) => {
    return;
  };

  const onValuesChange = (value: any, _allValues: any) => {
    if (value.centre) {
      dispatch(getCourseListByCenter({ center_id: value.centre })).then(
        (val: any) => {
          if (val?.payload?.data?.data?.length > 0) {
            setCourses(
              val.payload.data.data.map((val: any) => {
                return {
                  label: val.course_ref.course_name,
                  value: val.course_ref._id,
                };
              })
            );
          }
        }
      );
    }
  };
  var colSpan = screenType.isPortrait ? 24 : 12;
  const formProps: GenericFormProps = {
    name: "creat-edit-enquiry",
    heading: selectedEnquiry ? "Edit Enquiry" : "Add New Enquiry",
    initialValues: {
      lead_status: 'New',
      ...selectedEnquiry,
      course: selectedEnquiry?.course?._id,
      centre: selectedEnquiry?.centre?._id,
      exam: selectedEnquiry?.exam?._id,
      current_counsellor: selectedEnquiry?.current_counsellor?._id,
      next_follow_up_date: selectedEnquiry?.next_follow_up_date ? moment(selectedEnquiry?.next_follow_up_date) : null
    },
    formElements: [
      {
        name: "session",
        colSpan: 24,
        label: "Session",
        placeholder: "Select Session",
        type: "Select",
        options: sessionList,
        allowClear: true,
        rules: [
          {
            required: true,
            message: "Required",
          },
        ]
      },
      {
        name: "centre",
        label: "Centre",
        placeholder: "Centre",
        type: "Select",
        colSpan: colSpan,
        showSearch: true,
        allowClear: true,
        options: centers,
        rules: [
          {
            required: true,
            message: "Required",
          },
        ]
        // [
        //   { value: "Vanik CRP", label: "Vanik CRP" },
        //   { value: "Vanik Acharya Vihar", label: "Vanik Acharya Vihar" },
        //   { value: "Vanik Saheed Nagar", label: "Vanik Saheed Nagar" },
        //   { value: "Vanik Cuttack", label: "Vanik Cuttack" },
        //   { value: "Vanik Berhampur", label: "Vanik Berhampur" },
        //   { value: "Vanik Kolkata", label: "Vanik Kolkata" },
        //   { value: "Vanik Sambalpur", label: "Vanik Sambalpur" },
        //   { value: "Vanik Rourkela", label: "Vanik Rourkela" },
        //   { value: "Vanik Balasore", label: "Vanik Balasore" },
        // ],
      },
      {
        name: "rating",
        label: "Rating",
        placeholder: "Rating",
        type: "Select",
        colSpan: colSpan,
        showSearch: true,
        allowClear: true,
        options: [
          { value: "Hot", label: "Hot" },
          { value: "Warm", label: "Warm" },
          { value: "Cold", label: "Cold" },
        ],
      },

      {
        name: "course",
        label: "Course",
        placeholder: "Course",
        type: "Select",
        colSpan: colSpan,
        showSearch: true,
        allowClear: true,
        options: courses,
        rules: [
          {
            required: true,
            message: "Required",
          },
        ]
        // ?.map((course: string) => {
        //   return { value: course, label: course };
        // }),
      },

      {
        name: "lead_status",
        label: "Lead Status",
        placeholder: "Lead Status",
        type: "Select",
        colSpan: colSpan,
        showSearch: true,
        allowClear: true,
        options: [
          { value: "New", label: "New" },
          { value: "Not Contactable", label: "Not Contactable" },
          { value: "Not Interested", label: "Not Interested" },
          { value: "Converted", label: "Converted" },
        ],
      },

      {
        name: "exam",
        label: "Exam",
        placeholder: "Exam",
        type: "Select",
        colSpan: colSpan,
        showSearch: true,
        allowClear: true,
        options: exams
      },
      {
        name: "lead_type",
        label: "Lead Type",
        placeholder: "Lead Type",
        type: "Select",
        colSpan: colSpan,
        showSearch: true,
        allowClear: true,
        options: LEAD_TYPES,
      },

      {
        name: "user_name",
        label: "Candidate's Name",
        placeholder: "Candidate's Name",
        type: "Input",
        colSpan: colSpan,
        rules: [
          {
            required: true,
            message: "Name is required",
          },
          {
            max: 50,
            message: "Max Length is 50 chars",
          },
          {
            pattern: alphabetPattern,
            message: "Only Alphabets are allowed",
          },
        ],
      },
      {
        name: "lead_source",
        label: "Lead Source",
        placeholder: "Lead Source",
        type: "Select",
        colSpan: colSpan,
        showSearch: true,
        allowClear: true,
        options: leadSources.map((val: any) => {
          return { value: val, label: val }
        }),
      },
      {
        name: "user_email",
        label: "Email ID",
        placeholder: "Email ID",
        type: "Input",
        colSpan: colSpan,
        rules: [
          {
            type: "email",
            message: "Email ID invalid",
          },
          {
            max: 50,
            message: "Max length is 50 chars",
          },
        ],
      },
      {
        name: "next_follow_up_date",
        label: "Next Follow-up Date",
        placeholder: "Next Follow-up Date",
        type: "DateTimePicker",
        colSpan: colSpan,
        disabledDate: (d: any) => {
          return d <= moment();
        },
        rules: [
          {
            required: true,
            message: "Required",
          },
        ]
      },
      {
        name: "relation",
        label: "Relation",
        placeholder: "Select Relation",
        type: "Select",
        allowClear: true,
        options: RELATIONS.map((val: any) => {
          return { label: val, value: val }
        }),
        colSpan: colSpan,
      },
      {
        name: "user_number",
        label: "Mobile",
        placeholder: "Mobile",
        type: "Input",
        colSpan: colSpan,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            max: 20,
            message: "Max length is 20",
          },
        ],
      },
      {
        name: "qualification",
        label: "Qualification",
        placeholder: "Qualification",
        type: "Input",
        colSpan: colSpan,
        rules: [
          {
            max: 100,
            message: "Max Length is 100 chars",
          },
        ],
      },
      {
        name: "college",
        label: "College Name",
        placeholder: "College Name",
        type: "Input",
        colSpan: 24,
        rules: [
          {
            max: 100,
            message: "Max Length is 100 chars",
          },
        ],
      },


      // {
      //   name: "batch_timing",
      //   label: "Preferred Batch Timing",
      //   placeholder: "Preferred Batch Timing",
      //   type: "Select",
      //   colSpan: 12,
      //   showSearch: true,
      //   allowClear: true,
      //   options: [
      //     { value: "Day", label: "Day" },
      //     { value: "Morning", label: "Morning" },
      //     { value: "Afternoon", label: "Afternoon" },
      //     { value: "Evening", label: "Evening" },
      //   ],
      // },



      // {
      //   name: "action_taken",
      //   label: "Action Taken",
      //   placeholder: "Action Taken",
      //   type: "Select",
      //   colSpan: 12,
      //   showSearch: true,
      //   allowClear: true,
      //   options: [{ value: "Enquiry", label: "Enquiry" }],
      // },
      // {
      //   name: "address",
      //   label: "Correspondence Address",
      //   placeholder: "Correspondence Address",
      //   type: "TextArea",
      //   colSpan: 12,
      //   rows: 4,
      // },
      {
        name: "remarks",
        label: "Remarks",
        placeholder: "Remarks",
        type: "TextArea",
        colSpan: 24,
        rows: 4,
      },
    ],
    extraButtons: [
      {
        htmlType: "button",
        colSpan: 5,
        loading: false,
        submitButtonText: "Cancel",
        onClick: onClose,
      },
      {
        htmlType: "button",
        colSpan: 5,
        loading: false,
        submitButtonText: "Clear",
        type: "clearForm",
      },
      {
        htmlType: "submit",
        colSpan: 5,
        loading: false,
        submitButtonText: "Save",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFinish,
    onFinishFailed: onFinishFailed,
    onValuesChange: onValuesChange,
  };
  return (
    <>
      <Drawer
        maskClosable={false}
        title={<strong>{formProps.heading}</strong>}
        className="form-drawer"
        width={screenType.isPortrait ? '90%' : (screenType.isLg || screenType.isXl ? 720 : 500)}
        onClose={onClose}
        open={visible}
      >
        <GenericFormComponent {...formProps} />
      </Drawer>
    </>
  );
};

export default CreateEditEnquiry;
