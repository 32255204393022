// export const API_BASE_URL: string = "http://15.207.7.226:5000/";
const location = window.location.origin.includes("localhost")
  ? "local"
  : "server";
export const API_BASE_URL: string =
  location == "local"
    ? "http://localhost:5000/"
    : "https://api.vanikonline.in/";
export const WEB_URL: string = "https://app.vanikonline.in/";
export const TABLE_ROWS_COUNT: number = 50;

export const RELATIONS = [
  "Self",
  "Father",
  "Mother",
  "Brother",
  "Sister",
  "Friends",
  "Relatives",
];

export const courses = [
  "Banking",
  "SSC Railway Banking",
  "Special SSC Railway",
  "SSC Rail Tech",
  "SSC GS",
  "OSSC",
  "Crash Course",
  "Defence",
  "PGDCA",
  "Subjectwise Improvement",
  "Topicwise Improvement",
  "Special Improvement",
  "Online Test",
  "Exclusive IAS",
  "IAS",
  "OAS",
  "CMB",
  "Excl. OAS",
  "OMAS",
  "Foundation Course",
  "ASO",
];

export const leadSources = [
  "None",
  "Advertisement",
  "Employee Referral",
  "External Referral",
  "Newspaper",
  "Student / Friend Referral",
  "Posters",
  "Public Relations",
  "Seminar - Internal",
  "Seminar - Partner",
  "Trade Show",
  "Web",
  "Word of mouth",
  "Other",
];
export const examsEnquiry = [
  "OSSSC+SSC+BANK+RLY[OSBR]",
  "BANKING+SSC+RAILWAY[SB]",
  "SSC+OSSC[SOS]",
  "BANKING[BN]",
  "SSC TECHNICAL[ST]",
  "SSC EXCLUSIVE[SE]",
  "SSC GS ONLY[GS]",
  "OSSC JE[OJE]",
  "IAS[IAS]",
  "OAS[OAS]",
  "IAS+OAS[I+O]",
  "EX-OCS & OPTIONAL[OCWOP]",
  "OCS[EOCS]",
  "OAS MAIN CRASH COURSE[OMC]",
  "OMASE[OMAS]",
  "ONLINE TEST & LIVE DISCUSSION",
  "ONLINE TEST & OFFLINE DISCUSSION",
  "OFFLINE TEST & OFFLINE DISCUSSION",
  "ASSISTANT SECTION OFFICER[ASO]",
  "HIGH COURT ASSISTANT SECTION OFFICER [HCA]",
  "SECRETARIAT ASSISTANT SECTION OFFICER [SCA]",
  "TRAINED GRADUATE TEACHER[TGT]",
  "PGDCA",
  "SKILL TEST",
  "SUBJECT WISE IMPROVEMENT",
];

export const LEAD_TYPES = [
  { value: "Walk-in", label: "1] Walk-in", key: "1" },
  { value: "Talk-in", label: "2] Talk-in", key: "2" },
  { value: "Web Enquiry", label: "3] Web Enquiry", key: "3" },
  { value: "Social Media ", label: "4] Social Media ", key: "4" },
  { value: "Seminar", label: "5] Seminar", key: "5" },
  { value: "Marketing", label: "6] Marketing", key: "6" },
  { value: "Paid Data", label: "7] Paid Data", key: "7" },
  { value: "Scholarship Data", label: "8] Scholarship Data", key: "8" },
];
